import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import DeviceIcons from '../aaw_icons.png';

function Cards() {
  return (
    <div className='cards'>
      <h1>A Progressive Web App (PWA) is a secure website that looks and behaves like a mobile, web or desktop app. Takes advantage of native mobile device features. Works offline. Send user notifications of updates. Add to home screen without visiting an app store.</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/card-bg.jpg'
              icon='images/blue-linkable.png'
              text='Share apps by sending a URL via email or any multimedia platform'
              label='LINKABLE'
              path='/linkable'
            />
            <CardItem
              src='images/card-bg.jpg'
              icon='images/blue-responsive.png'
              text='Work offline if the connection is lost. Improving the user experience'
              label='WORK OFFLINE'
              path='/work-offline'
            />          
            <CardItem
              src='images/card-bg.jpg'
              icon='images/blue-rocket.png'
              text='Instant loading, increased user engagement, low data consumption'
              label='FAST'
              path='/fast'
            />
            </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/card-bg.jpg'
              icon='images/blue-installable.png'
              text='Add to any device home screen or via an app Store if required'
              label='INSTALLABLE'
              path='/installable'
            />            
            <CardItem
              src='images/card-bg.jpg'
              icon='images/blue-discoverable.png'
              text='Find apps via internet search engines and reach a wider audience'
              label='DISCOVERABLE'
              path='/discoverable'
            />
            <CardItem
              src='images/card-bg.jpg'
              icon='images/blue-reliable.png'
              text='PWAs feel fast and dependable regardless of the network'
              label='RELIABLE'
              path='/reliable'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/card-bg.jpg'
              icon='images/blue-secure.png'
              text='HTTPS encrypted connections between users, app, and server'
              label='SECURE'
              path='/secure'
            />
            <CardItem
              src='images/card-bg.jpg'
              icon='images/blue-responsive.png'
              text='Usable on any device independant of screen size from PC to any mobile'
              label='RESPONSIVE'
              path='/responsive'
            />            
            <CardItem
              src='images/card-bg.jpg'
              icon='images/blue-integrated.png'
              text='Simply installed on home screen without having to visit app stores'
              label='INTEGRATED'
              path='/integrated'
            />
            </ul>
            <ul className='cards__items'>
            <CardItem
              src='images/card-bg.jpg'
              icon='images/blue-engaging.png'
              text='Ability to send users notifications whenever new content is available'
              label='ENGAGING'
              path='/engaging'
            />
            <CardItem
              src='images/card-bg.jpg'
              icon='images/blue-cross-platform.png'
              text='Run on any device with native look and feel, saving time and money'
              label='CROSS PLATFORM'
              path='/cross-platform'
            />
            <CardItem
              src='images/card-bg.jpg'
              icon='images/blue-native.png'
              text='Use all phone capabilities like GPS, fingerprint recognition, camera etc.'
              label='NATIVE FEATURES'
              path='/native-features'
            />
          </ul> 
          
          <img src={DeviceIcons} height="30" alt="DeviceIcons" className="center"/>
          <br></br>
          <h2>Multiple browsers support PWAs with instant install and add to homescreen experiences on mobile.</h2> 
          <h2>Install PWAs on desktop browsers, Windows store, Android, iOS, iPad and iPhones.</h2>
          <h2>Android Play store and Apple App store integration for that true native experience.</h2>
        </div>
      </div>
    </div>
  );
}

export default Cards;
