import React from 'react';
import '../../App.css';
import Footer from '../Footer';

export default function Engaging() {

  return (
    <>
      <h1 className='engaging'>ENGAGING</h1>
      <Footer />
    </>
    );
}
