import React from 'react';
import '../../App.css';
import Footer from '../Footer';

export default function CrossPlatform() {

  return (
    <>
      <h1 className='cross-platform'>CROSS PLATFORM</h1>
      <Footer />
    </>
    );

}
