import React from 'react';
import '../../App.css';
import Footer from '../Footer';

export default function Discoverable() {

  return (
    <>
      <h1 className='discoverable'>DISCOVERABLE</h1>
      <Footer />
    </>
    );

}
