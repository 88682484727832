import React from 'react';
import '../../App.css';
import Footer from '../Footer';

export default function WorkOffline() {

  return (
    <>
      <h1 className='work-offline'>WORK OFFLINE</h1>
      <Footer />
    </>
    );

}
