import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './App.css';

import Home from './components/pages/Home';
import Navbar from './components/Navbar';
import Services from './components/pages/Services';
import Products from './components/pages/Products';
import SignUp from './components/pages/SignUp';
import Engaging from './components/pages/Engaging';
import Linkable from './components/pages/Linkable';
import WorkOffline from './components/pages/WorkOffline';
import Fast from './components/pages/Fast';
import Installable from './components/pages/Installable';
import Integrated from './components/pages/Integrated';
import Discoverable from './components/pages/Discoverable';
import Reliable from './components/pages/Reliable';
import Secure from './components/pages/Secure';
import Responsive from './components/pages/Responsive';
import CrossPlatform from './components/pages/CrossPlatform';
import NativeFeatures from './components/pages/NativeFeatures';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/services' component={Services} />
          <Route path='/products' component={Products} />
          <Route path='/sign-up' component={SignUp} />
          <Route path='/engaging' component={Engaging} />
          <Route path='/linkable' component={Linkable} />
          <Route path='/work-offline' component={WorkOffline} />
          <Route path='/fast' component={Fast} />
          <Route path='/installable' component={Installable} />
          <Route path='/integrated' component={Integrated} />
          <Route path='/discoverable' component={Discoverable} />
          <Route path='/reliable' component={Reliable} />
          <Route path='/secure' component={Secure} />
          <Route path='/responsive' component={Responsive} />
          <Route path='/cross-platform' component={CrossPlatform} />
          <Route path='/native-features' component={NativeFeatures} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
